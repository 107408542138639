:root {
    --small: 6px;
    --medium: 10px;
    --large: 14px;
    --xlarge: 20px;
    --xxlarge: 26px;
}

.bold {
    font-weight: bold;
}

.heading {
    color: rgb(89, 89, 89);
}

.l-gray {
    color: #656565;
}

.h5 {
    font-size: 1.2rem;
}

.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-6 {
    opacity: 0.6;
}

.mt-sm {
    margin-top: var(--small);
}

.mt-lg {
    margin-top: var(--large);
}

.ml-sm {
    margin-left: var(--small);
}

.ml-lg {
    margin-left: var(--large);
}

.mr-sm {
    margin-right: var(--small);
}

.mr-lg {
    margin-right: var(--large);
}

.mb-sm {
    margin-bottom: var(--small);
}

.mb-lg {
    margin-bottom: var(--large);
}

.mb-xxlg {
    margin-bottom: var(--xxlarge);
}

.pt-sm {
    padding-top: var(--small);
}

.pt-lg {
    padding-top: var(--large);
}

.pl-sm {
    padding-left: var(--small);
}

.pl-lg {
    padding-left: var(--large);
}

.pr-sm {
    padding-right: var(--small);
}

.pr-lg {
    padding-right: var(--large);
}

.pb-sm {
    padding-bottom: var(--small);
}

.pb-lg {
    padding-bottom: var(--large);
}

.p-sm {
    padding: var(--small);
}

.p-lg {
    padding: var(--large);
}

.hover-pointer:hover {
    cursor: pointer;
}

.bg-white {
    background-color: white;
}

.bg-red {
    background-color: red;
}

.width-100 {
    width: 100%;
}

.set-scroll {
    overflow: auto;
}
