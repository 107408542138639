/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

@font-face {
    font-family: "Diodrum-Regular";
    font-style: normal;
    font-weight: 400;
    src: local("Diodrum-Regular"),
        url("fonts/Diodrum-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Diodrum-Semibold";
    font-style: normal;
    font-weight: 600;
    src: local("Diodrum-Semibold"),
        url("fonts/Diodrum-Semibold.otf") format("opentype");
}

:root {
    /* --main-bg: #fafafb; */
    --main-bg: #ebebeb;
    --txt-color: #455560;
    --second-bg: #ffffff;
    --sidebar-bg: #e1e1e1;
    --topnav-height: 55px;
    --sidebar-width: 22%;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html {
    zoom: 90%;
}

body {
    /* font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */

    font-family: "Diodrum-Regular";
    letter-spacing: 0.7px;
    background-color: var(--main-bg);
    color: var(--txt-color);
    overflow: hidden;
    color: #313131 !important;
}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    color: var(--main-color);
}

ul {
    list-style-type: none;
}

h2.page-header {
    font-family: "Diodrum-Semibold";
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: #313131 !important;
}

h3 {
    font-family: "Diodrum-Semibold";
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    position: relative;
    margin: 5px;
}

::-webkit-scrollbar-thumb {
    background: #349eff;
    width: 5px;
    height: 5px;
    border-radius: 5px;
}

.chatgpt .message {
    background-color: var(--second-bg);
    /* var(--main-bg); */
    padding: 10px;
    box-shadow: var(--box-shadow);
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.chatgpt .question {
    margin-right: 20px;
    min-width: 30%;
    max-width: 80%;
    line-height: 0;
    /* text-align: justify;
    text-justify: inter-word; */
}

.chatgpt .answer {
    /* var(--main-bg); */
    margin-top: 5px;
    margin-bottom: 10px;
    /* width: 95% !important; */
    /* text-align: justify;
    text-justify: inter-word; */
    line-height: 1.5;
    /* text-align: justify; */
    color: #313131 !important;
    font-size: 16px;
}

.scrollview::-webkit-scrollbar-button:single-button {
    background-color: #fff;
    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
}

/* Up */
.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-radius: 5px 5px 0 0;
    height: 16px;
    width: 16px;
    background-position: center 4px;
    background-color: #e1e1e1;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
.scrollview::-webkit-scrollbar-button:single-button:vertical:increment {
    border-radius: 0px 0px 5px 5px;
    height: 16px;
    width: 16px;
    background-position: center 4px;
    background-color: #e1e1e1;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement {
    border-radius: 5px 0px 0 5px;
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment {
    border-radius: 0 5px 5px 0;
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.defaultMessage {
    list-style-type: disc !important;
    list-style-type: inside;
    margin-top: 15px;
    margin-left: 35px;
}

.defaultMessage li {
    margin-bottom: 15px;
}

@import url("./styles/theme.css");
@import url("./styles/layout.css");
@import url("./styles/header.css");
@import url("./styles/sidebar.css");
@import url("./styles/antd.css");
@import url("./styles/chatGPT.css");
@import url("./styles/global.css");
@import url("./styles/uploadButton.css");
@import url("./styles/importTab.css");
@import url("./styles/spinners.css");
@import url("./styles/queueTab.css");
@import url("./styles/previewTab.css");
@import url("./styles/table.css");
@import url("./styles/dashboard.css");