.queue-side-bar-container {
    margin-top: -15px;
    border-left: 1px solid rgb(199, 199, 199);
}

.queue-side-bar-container div {
    padding: var(--small);
}

.queue-sidebar-head {
    padding: var(--large) !important;
}

.queue-side-propmts-container {
    overflow: auto !important;
    max-height: 85vh !important;
}