.table-container {
    max-width: 100vw;
    min-width: 50vw;
    height: 85vh;
}

.table-container tr {
    width: 100%;
}

.table-container thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    bottom: 0;
    background-color: #e2e2e2;
}

.table-container th {
    padding: var(--medium);
    width: 20rem !important;
    width: 5000px;
}

.table-container td {
    padding: var(--medium);
    width: 15rem;
    background-color: white;
    vertical-align: top;
}