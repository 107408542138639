.import-tab {}

.import-header {
    padding-right: 3rem;
    margin-top: -20px ;
}

.import-container {}

.import-tab-main {}

.import-prompt-input-container {
    display: grid;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-template-columns: auto auto ;
    row-gap: 10px;
    column-gap: 0%;
    max-height: 70vh;
    padding-bottom: var(--large);
    overflow: auto;
    overflow-x: hidden;

}

.add-prompt-btn {
    height: 9.8rem;
    width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: var(--medium);
    margin-top: 0px !important;
    margin-left: 20px;
}

.add-prompt-btn:hover {
    cursor: pointer;
}