.upload-comp-wrapper {
    width: 35vw;
}

.upload-comp-wrapper > span {
    color: #40a9ff;
    font-size: 0.9rem;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    display: flex;
    flex-direction: row;
    width: 75%;
}

.btn {
    border: 2px solid gray;
    color: white;
    background-color: #40a9ff;
    padding: 8px 20px;
    border-radius: 8px;
    outline: none;
}

/* .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
} */

.upload-btn-wrapper .ant-btn:not([disabled]):hover {
    background-color: #707070 !important;
}

.upload-btn-footer {
    margin-top: 7px;
}

.upload-btn-footer + button {
    margin-right: 7px;
}
